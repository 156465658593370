import React from "react"
import { Link } from "gatsby"
import "../../i18n.js";
import { useTranslation } from "react-i18next";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import BlogBanner from "../components/blog-banner/blogBanner"
import Ticker from "../components/ticker";
// import Popup from "../components/popup";

const Space = () => {
  // const [openModal, setOpenModal] = useState(true);
  const { t } = useTranslation();

  // const onClose = () => setOpenModal(false);

  /* useEffect(() => {
    if (openModal) {
      document.body.classList.add('h-screen', 'overflow-hidden');
    } else {
      document.body.classList.remove('h-screen', 'overflow-hidden');
    }
  }, [openModal]); */
  
  return (
    <Layout className="bg-blue">
      <Seo title={t("metaSpaceTitle")} description={t("metaSpaceDescription")} canonical="https://tio.ist/space/" />
      {/* <Popup isOpen={openModal} onClose={onClose} /> */}
      <div className="bg-blue mt-12">
        <p className="text-lightBlue text-center lg:text-xl pb-12">{t("spaceSubTitle")}</p>
        <Ticker color="white" text={t("space")} type="sub" />
        <div className="flex justify-center my-8">
          <svg className="animate-bounce text-center fill-current text-lightBlue" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.35938 -4.76837e-07V12.8438L13.2969 7.89062L14.5156 9.09375L7.5 16.0938L0.5 9.09375L1.6875 7.89062L6.64063 12.8438V-4.76837e-07H8.35938Z" />
          </svg>
        </div>

        <div className="max-w-xs md:max-w-2xl text-cream mx-5 md:mx-14 lg:mx-44 mb-40 md:mb-44 lg:mb-72 mt-16">
          <p className="text-2xl lg:text-3xl">
            {t("spaceSection1Description1")}{" "}
            <span className="font-bold">{t("spaceSection1Description2")}</span>{" "}
            {t("spaceSection1Description3")}
          </p>
        </div>

        <div className="mt-16 md:mt-32 lg:mt-16 mx-5 md:mx-14 lg:mx-44 flex justify-end">
          <div className="max-w-xs md:max-w-3xl flex flex-col">
            <div>
              <StaticImage src="../images/space-1.png" alt="workspace" formats={["auto", "webp", "avif"]} />
            </div>
            <div className="text-2xl lg:text-3xl text-cream mt-11 md:mt-14 lg:mt-16">
              {t("spaceSection2Description")}
            </div>
          </div>
        </div>

        <div className="mx-5 md:mx-14 lg:mx-44 mt-16 lg:mt-32 mb-32">
          <Link to="/space/book-a-spot" className="group text-white italic font-light text-6xl lg:text-9xl">
            <svg className="inline w-14 h-20" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.4999 40.9997L20.2726 37.2725L36.8181 23.1361H0.0908203L0.954457 18.1361H38.7726L26.4999 3.40882L30.2726 0.272461L47.2726 20.6361L23.4999 40.9997Z" fill="white" />
            </svg>{" "}
            <span className="border-b-2 border-transparent group-hover:border-white">{t("bookaSpotCTA")}</span>
          </Link>
        </div>

        <div className="mx-5 md:mx-14 lg:mx-44">
          <StaticImage src="../images/space-2.png" alt="workspace" formats={["auto", "webp", "avif"]} />
        </div>

        <div className="mt-64">
          <h2>
            <p className="mx-5 md:mx-52 lg:mx-96 text-6xl md:text-8xl lg:text-9xl font-extrabold text-white">
              {t("workSpaceTitle")}
            </p>
          {/*   <div className="ticker-wrap-v2 bg-transparent text-white text-6xl md:text-8xl lg:text-9xl font-light italic">
              <div className="ticker z-10">
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
              </div>
              <div className="ticker z-10">
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
                <div className="ticker-item w-auto mr-32">
                  <p>{t("workSpaceTickerTitle")}</p>
                </div>
              </div>
            </div> */}

          <div className="flex flex-row text-white text-6xl md:text-8xl lg:text-9xl font-light italic overflow-hidden">
              <div className="flex flex-row items-center flex-none min-w-full animate-scroll-left">
                  <p className="w-full">{t("workSpaceTickerTitle")}</p>
              </div>
              <div className="flex flex-row items-center flex-none min-w-full animate-scroll-left">
                <p className="w-full">{t("workSpaceTickerTitle")}</p>
              </div>
          </div>
          </h2>
        </div>

        <div className="relative mx-5 md:mx-14 lg:mx-44 mt-16 md:mt-11 lg:mt-8 mb-64">
          <p className="text-white text-xl hidden md:block max-w-md absolute top-28 right-28 z-20">{t("workSpaceDescription")}</p>
          <div className="z-10">
            <StaticImage src="../images/space-3.png" alt="workspace" formats={["auto", "webp", "avif"]} />
          </div>
          <div className="absolute right-8 -bottom-24 md:right-12 md:-bottom-52 lg:-bottom-44 lg:right-4 w-2/3 z-20">
            <div>
              <StaticImage src="../images/space-4.png" alt="workspace" formats={["auto", "webp", "avif"]} />
            </div>
            <p className="absolute -left-12 -bottom-6 md:top-1/2 md:left-1/2 md:-translate-y-1/2 md:-translate-x-1/2 lg:bottom-12 lg:left-24 z-40">
              <Link to="/space/book-a-spot" className="group text-cream italic font-light text-4xl md:text-6xl lg:text-9xl whitespace-nowrap">
                <span className="border-b-2 border-transparent group-hover:border-white">{t("bookaSpotCTA")}</span>{" "}
                <svg className="inline-block w-7 h-10 lg:w-14 lg:h-20 fill-current text-cream" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.4999 40.9997L20.2726 37.2725L36.8181 23.1361H0.0908203L0.954457 18.1361H38.7726L26.4999 3.40882L30.2726 0.272461L47.2726 20.6361L23.4999 40.9997Z" />
                </svg>
              </Link>
            </p>
          </div>
        </div>

      </div>
      <BlogBanner />
    </Layout>
  )
}

export default Space